import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  justify-content: center;
  padding: 30px 0;
`;

export const Main = styled.main`
  @font-face {
    font-family: "Awesome";
    src: url("/aw-content/Awesome-Regular.woff");
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-family: "Awesome";
    src: url("/aw-content/Awesome-Italic.woff");
    font-style: italic;
    font-weight: 400;
  }
  @font-face {
    font-family: "Awesome";
    src: url("/aw-content/Awesome-Semibold.woff");
    font-style: normal;
    font-weight: 700;
  }
  font-family: "Awesome";
  margin: -8px;
  max-width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 15% 1fr 10%;
  text-align: center;
  @media screen and (max-width: 767px) {
    background-color: #d0ffe2;
  }
`;

export const Content = styled.div`
  h1,
  p {
    max-width: 840px;
    margin: auto;

    @media screen and (max-width: 767px) {
      margin: 0 16px;
    }
  }
  h1 {
    color: #047364;
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 24px;
  }
  p {
    color: #012d28;
    font-size: 1.1rem;
    letter-spacing: 0.2px;
    margin-bottom: 48px;
  }
`;

export const Links = styled.div`
  margin: 0 auto;
  max-width: 540px;
`;

export const LinkButton = styled.a`
  padding: 10px;
  margin: 10px;
  background-color: #05d48e;
  color: #fff;
  display: inline-block;
  width: 140px;
  font-size: 1rem;
  text-decoration: none;
`;

export const Footer = styled.footer`
  width: 100%;
  background-color: #047364;
  color: #fff;
  padding: 16px 0;
  a {
    color: #fff;
  }
  img {
    width: 120px;
  }
  p {
    @media screen and (max-width: 767px) {
      margin: 0 16px;
    }
  }
`;

import * as React from "react";
import logo from "../images/logo_main_green.svg";
import logoWhite from "../images/logo_small_footer.svg";
import { Content, Footer, Header, LinkButton, Links, Main } from "../styles";
import "../global.css";

const IndexPage = () => {
  return (
    <Main>
      <title>Academic Work</title>

      <Header>
        <img alt="logo" src={logo} />
      </Header>
      <Content>
        <h1>Home of the young professionals</h1>
        <p>
          We offer jobs for students or young professionals in the beginning of their career - with us you can work as a
          consultant on specific client assignments or you can become employed directly by one of our customer companies
          through our recruitment service.
        </p>
        <Links>
          {links.map((link) => (
            <LinkButton href={link.href}>{link.label}</LinkButton>
          ))}
        </Links>
      </Content>
      <Footer>
        <p>
          Copyright © {new Date().getFullYear()} Academic Work |{" "}
          <a href="mailto:info@academicwork.se">info@academicwork.se</a> | +46 8 562 448 00
        </p>
        {/* <img alt="logo footer" src={logoWhite}></img> */}
      </Footer>
    </Main>
  );
};

export default IndexPage;

const links = [
  {
    label: "Sweden",
    href: "https://www.academicwork.se",
  },
  {
    label: "Denmark",
    href: "https://www.academicwork.dk",
  },
  {
    label: "Finland",
    href: "https://www.academicwork.fi",
  },
  {
    label: "Germany",
    href: "https://www.academicwork.de",
  },
  {
    label: "Norway",
    href: "https://www.academicwork.no",
  },
  {
    label: "Switzerland",
    href: "https://www.academicwork.ch",
  },
];
